.App {
  text-align: center;
}

.nav {
  background: rgb(254,216,101);
  background: -moz-linear-gradient(90deg, rgba(254,216,101,1) 0%, rgba(255,193,7,1) 50%, rgba(252,176,69,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(254,216,101,1) 0%, rgba(255,193,7,1) 50%, rgba(252,176,69,1) 100%);
  background: linear-gradient(90deg, rgba(254,216,101,1) 0%, rgba(255,193,7,1) 50%, rgba(252,176,69,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fed865",endColorstr="#fcb045",GradientType=1);
}

.vw-50 {
  max-width: 50vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.large-logo {
  width: 80%;
  max-width: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hex {
  position: relative;
  margin: 4px 10px;
  font-size: 16px;
  bottom: 1px;
}

.list-item {
  display: inline-flex
}

@keyframes bee-animation {
  0% {transform: rotate(.25turn); visibility: visible; left: 25vw; top: 30vh}
  25% {transform: rotate(.25turn);visibility: visible; left: 50vw; top: 50vh}
  50%  {transform: rotate(-.25turn);visibility: visible; left: 70vw; top: 70vh;}
  75%  {transform: rotate(.25turn);visibility: visible; left: 40vw; top: 40vh;}
  100% {visibility: visible; left: 25vw; top: 25vh;}
}

.bee {
  position: absolute;
  visibility: hidden;
  left: 20vw;
  top: 40vh;
  height: 50px;
  width: 50px;
  animation-name: bee-animation;
  animation-duration: 10s;
  z-index: 1000;
}

